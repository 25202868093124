<template>
  <div class="thumbnail-video-list">
    <div class="thumbnail-video-list__header">
      <T3HtmlParser
        v-if="header"
        tag="h2"
        class="thumbnail-video-list__header-title"
        :content="header"
      />

      <T3HtmlParser
        v-if="bodytext"
        class="thumbnail-video-list__header-text"
        :content="bodytext"
      />

      <T3Link
        v-if="subheader"
        :to="headerLink"
        class="thumbnail-video-list__header-link"
      >
        {{ subheader }}
      </T3Link>
    </div>

    <div v-if="itemList.length" class="thumbnail-video-list__content">
      <div
        v-for="item in itemList"
        :key="item.id"
        class="thumbnail-video-list__tile"
        :class="variantColorClass(item)"
      >
        <component
          :is="isLinkValid(item.link) ? 't3-link' : 'div'"
          :to="item.link"
          class="thumbnail-video-list__tile-thumbnail"
          :data-label="item.label || null"
        >
          <UiImg :image="item.image" :sizes="{ xs: '504' }" use-image-size />
        </component>

        <h3>
          <component
            :is="isLinkValid(item.link) ? 't3-link' : 'span'"
            :to="item.link"
          >
            {{ item.name }}
          </component>
        </h3>
      </div>
    </div>

    <div class="thumbnail-video-list__footer">
      <T3Link :to="links.b2bMc">
        <B2BMCLogo />
      </T3Link>

      <div class="thumbnail-video-list__footer-links">
        <T3Link :to="links.applePodcasts">
          <ApplePodcastsIcon />
        </T3Link>

        <T3Link :to="links.googlePodcasts">
          <GooglePodcastsIcon />
        </T3Link>

        <T3Link :to="links.spotify">
          <SpotifyIcon />
        </T3Link>

        <T3Link :to="links.youTube">
          <YouTubeIcon />
        </T3Link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useTheming from '~/composables/useTheming'
import type { UiCeProductBoxesItemProps, UiCeProductBoxesProps } from '~/types'
import ApplePodcastsIcon from '~ui/assets/social-icons/apple-podcasts.svg'
import B2BMCLogo from '~ui/assets/social-icons/b2bmc.svg'
import GooglePodcastsIcon from '~ui/assets/social-icons/google-podcasts.svg'
import SpotifyIcon from '~ui/assets/social-icons/spotify.svg'
import YouTubeIcon from '~ui/assets/social-icons/youtube.svg'
import { UiImg } from '~ui/components'
import { isLinkValid } from '~ui/helpers/linkUtils'

defineProps<UiCeProductBoxesProps>()

const variantColorClass = (item: UiCeProductBoxesItemProps) =>
  useTheming(item.variant)

const links = {
  b2bMc: 'https://www.b2b-masterclass.macopedia.com/',
  applePodcasts:
    'https://podcasts.apple.com/pl/podcast/b2b-master-class/id1703580449',
  googlePodcasts:
    'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9lNTk0OTdmYy9wb2RjYXN0L3Jzcw',
  spotify: 'https://open.spotify.com/show/3dxNTREf1ycMB8ulq6Cs5N',
  youTube:
    'https://youtube.com/playlist?list=PLPCr2EIkIgImMjKrm1bAI6UY0KWiG8r8y'
}
</script>

<style lang="scss">
.thumbnail-video-list {
  display: grid;
  gap: rem(48px);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: rem(24px) rem(12px);

    @include media-query(max sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      font-size: rem(32px);
      font-weight: 600;
      margin: 0;
    }

    &-text > *:last-child {
      margin-bottom: 0;
    }

    &-link {
      color: color(dodger-blue);
      text-transform: uppercase;
      font-weight: 600;
      text-align: right;
    }
  }

  &__content {
    display: grid;
    grid-template: auto;
    gap: rem(8px);
    padding: em(8px);
    background-color: rgba(color(black), 3%);
    border-radius: em(4px);
    overflow: hidden;

    @include media-query(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__tile {
    display: grid;
    gap: rem(24px);
    padding: em(16px);
    padding-bottom: em(24px);

    &-thumbnail {
      border-radius: em(2px);
      overflow: hidden;
      position: relative;

      &::before {
        position: absolute;
        top: rem(16px);
        right: rem(16px);
        z-index: z-index(over);
        padding: em(4px) em(8px);
        color: var(--theme__font-color, color(white));
        font-weight: 600;
        text-transform: uppercase;
        border-radius: inherit;
        background-color: var(--theme__theme-color, color(flamingo));
        text-overflow: clip;
        white-space: nowrap;
      }

      &[data-label]::before {
        content: attr(data-label);
      }

      & > img {
        width: 100%;
        height: 100%;
        transition: scale 0.3s;
      }

      &:is(:hover, :focus) > img {
        scale: 1.02;
      }
    }

    & h3 {
      display: inline-block;
      font-size: rem(24px);
      font-weight: 600;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(8px);

    @include media-query(max sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    & svg {
      height: 48px;
      width: fit-content;
    }

    &-links {
      display: flex;
      gap: rem(8px);

      & a {
        display: inline-block;
        border-radius: em(2px);
        border: 1px solid rgba(color(black), 8%);

        & svg {
          height: 44px;

          path {
            fill: color(dodger-blue);
          }
        }
      }
    }
  }
}
</style>
